/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useFormik } from "formik";
import _, { clone, rest } from 'lodash';
import * as alert from '../../../../redux/alertSnackBarRedux';
import { validateDescription, getErrorField } from '../../../../helpers/helper';
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import * as projectPinService from '../../../../services/service/projectPinService';
import { checkTierPlanAndRoleTier2, checkTierPlanAndRoleTier3 } from '../../../../helpers/helper';
import * as projectPinCommentService from '../../../../services/settings/projectPinCommentService';
import ResolvePOIStatusModal from '../../../components/ResolvePOIStatusModal';
import ProjectPinValidator from '../../../../helpers/validators/ProjectPinValidator';
import ProjectPinCommentValidator from "../../../../helpers/validators/ProjectPinCommentValidator";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import * as helper from '../../../../helpers/helper';
import { Button } from 'react-bootstrap';

const RedCheckbox = withStyles({
    root: {
        color: red[400],
        '&$checked': {
            color: red[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const moment = require('moment');

const NspectionPinsManagePage = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    // Extract projectPinId and other props from route params and location state
    const { match, showAlert, auth } = props;
    const projectPinId = match.params.id;
    const {
        tierPlan,
        poiStatus,
        user,
        project,
        imageString,
    } = location.state || {};
    const [pin, setPin] = useState({});
    const [poiStatusList, setPOIStatusList] = useState([]);
    const [pointCategoryList, setPointCategoryList] = useState([]);
    const [projectPinCheckBoxList, setProjectPinCheckBoxList] = useState([]);
    const [projectPinCheckBoxes, setProjectPinCheckBoxes] = useState([]);
    const [resolvePOIStatusModalVisible, setResolvePOIStatusModalVisible] = useState(false);
    const [isBusinessOrProfessionalTier, setIsBusinessOrProfessionalTier] = useState(false);
    const [selectedProjectTypeCustomField, setSelectedProjectTypeCustomField] = useState('');
    const [selectedProjectTypeCustomFields, setSelectedProjectTypeCustomFields] = useState([]);
    const [projectTypeCustomFieldList, setProjectTypeCustomFieldList] = useState([]);
    const [selectedCustomFieldIds, setSelectedCustomFieldIds] = useState([]);
    const [addCommentModalVisible, setAddCommentModalVisible] = useState(false);
    const [customFieldDescription, setCustomFieldDescription] = useState('');
    const [projectPinCommentList, setProjectPinCommentList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [customFieldsData, setCustomFieldsData] = useState([]);
    const [customFields, setCustomFields] = useState([]);
    const [resolveStatusModalRefreshStates, setResolveStatusModalRefreshStates] = useState(false);
    const [editedCustomField, setEditedCustomField] = useState(null);
    const [selectedPointCategory, setSelectedPointCategory] = useState(null);
    const [loading, setLoading] = useState(false);
    const [projectPinComment, setProjectPinComment] = useState({
        id: 0,
        projectPinId: 0,
        projectTypePoiStatusId: 0,
        comment: '',
        commentedBy: {},
        commentedOn: '',
        isDeleted: false
    });
    const [projectPinCommentsLoading, setProjectPinCommentsLoading] = useState(true);
    const [isBusinessTier, setIsBusinessTier] = useState(false);
    const [filteredProjectPinCustomFields, setFilteredProjectPinCustomFields] = useState([]);

    useEffect(() => {
        if (project && project.projectType) {
            setProjectTypeCustomFieldList(project.projectType.projectTypeCustomFields);
        }
        if (projectPinId) {
            fetchProjectPin(project.projectType.projectTypeCustomFields);
            assignPoiStatusListAndPointCategoryList(project.projectType.projectTypePOIStatuses, project.projectType.pointCategories);
        }
    }, [projectPinId]);

    useEffect(() => {
        if (pin && pin.pointCategoryId) {
            handlePointCategoryChange(selectedPointCategory ?? pin.pointCategoryId);
        }
    }, [customFields, pin]);

    useEffect(() => {
        if (selectedPointCategory && project && project.projectType && project.projectType.projectTypeCustomFields && pin) {
            const projTypeCustomFields = project.projectType.projectTypeCustomFields;
            const projPinCustomFields = pin.projectPinCustomFields || [];

            copyProjectTypeCustomFieldToProjectPinCustomField(projTypeCustomFields, projPinCustomFields);
        }
    }, [selectedPointCategory, project, pin]);

    useEffect(() => {
        let tierPlan3 = checkTierPlanAndRoleTier3(tierPlan, auth.role);
        setIsBusinessTier(tierPlan3);
        if (projectPinId && tierPlan3) {
            fetchProjectPinCommentsByPoiStatusId(projectPinId);
        }
    }, []);

    const fetchProjectPin = (projTypeCustomFields) => {
        projectPinService.getProjectPinById(projectPinId).then(res => {
            setPin(res);
            setProjectPinCheckBoxList(res.projectPinCheckBoxes);
            copyProjectTypeCustomFieldToProjectPinCustomField(projTypeCustomFields, res.projectPinCustomFields);
            setProjectPinCheckBoxes([...res.projectPinCheckBoxes]);
        });
    }

    const copyProjectTypeCustomFieldToProjectPinCustomField = (projTypeCustomFields, projPinCustomFields) => {
        let tempArr = projTypeCustomFields.map(typeField => {
            const clonedObj = { ...typeField };
            var projectTypeCustomFieldId = clonedObj['id'];
            const matchingCustomField = projPinCustomFields.find(pinField => pinField.projectTypeCustomFieldId === projectTypeCustomFieldId);
            if (matchingCustomField) {
                delete clonedObj['projectTypeId'];
                clonedObj.id = matchingCustomField.id;
                clonedObj.description = matchingCustomField.description ?? '';
            } else {
                delete clonedObj['id'];
                delete clonedObj['projectTypeId'];
                clonedObj.description = '';
            }
            return { ...clonedObj, projectTypeCustomFieldId: projectTypeCustomFieldId };
        });

        setCustomFields(tempArr);
    }

    const fetchProjectPinCommentsByPoiStatusId = (projPinId) => {
        projectPinCommentService.getProjectPinCommentByProjectPinId(projPinId).then(res => {
            setProjectPinCommentList(res);
            setProjectPinCommentsLoading(false);
        });
    }

    const assignPoiStatusListAndPointCategoryList = (projectTypePOIStatuses, pointCategories) => {
        let isTier2 = checkTierPlanAndRoleTier2(tierPlan, auth.role);
        setIsBusinessOrProfessionalTier(isTier2);
        isTier2 && setPOIStatusList(projectTypePOIStatuses);
        setPointCategoryList(pointCategories);
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: pin,
        validationSchema: ProjectPinValidator,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            updateProjectPin(values, setSubmitting);
        }
    });

    const formikForComments = useFormik({
        enableReinitialize: true,
        initialValues: projectPinComment,
        validationSchema: ProjectPinCommentValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            saveProjectPinComment(values, setSubmitting);
        }
    });

    const hasCustomFieldErrors = () => {
        for (let index = 0; index < filteredProjectPinCustomFields.length; index++) {
            const field = filteredProjectPinCustomFields[index];
            if (validateDescription(field.description, field.customFieldType, field.options)) {
                return true;
            }
        }
        return false;
    };

    const handleCheckboxChange = (e, index, option) => {
        const updatedFields = [...filteredProjectPinCustomFields];
        let selectedOptions = updatedFields[index].description ? updatedFields[index].description.split(', ') : [];

        if (e.target.checked) {
            selectedOptions = [...selectedOptions, option];
        } else {
            selectedOptions = selectedOptions.filter(item => item !== option);
        }

        updatedFields[index].description = selectedOptions.join(', ');
        setFilteredProjectPinCustomFields(updatedFields);
    };

    const handleDescriptionChange = (e, index) => {
        const updatedFields = [...filteredProjectPinCustomFields];
        const field = updatedFields[index];

        if (field.customFieldType.toLowerCase() === 'dropdown') {
            field.description = e.target.value;
        } else if (field.customFieldType.toLowerCase() === 'multiselect') {
            field.description = e.target.value.join(', ');
        } else {
            field.description = e.target.value;
        }

        setFilteredProjectPinCustomFields(updatedFields);
    };

    const saveProjectPinComment = (values, setSubmitting) => {
        setProjectPinCommentsLoading(true);
        var model = helper.convertJsonToFormData({
            ...values,
            projectPinId: pin.id,
            commentedBy: auth.user.firstName + " " + auth.user.lastName,
            ...isBusinessOrProfessionalTier
                ? { projectTypePoiStatusId: pin.projectTypePOIStatusId }
                : { projectTypePoiStatusId: null },
            commentedOn: moment().format('MM/DD/yyyy hh:mm a'),
            userId: user.id,
            ...poiStatus ? { projectTypePOIStatus: poiStatus } : { projectTypePOIStatus: pin.poiStatus },
            isDeleted: false,
        });
        projectPinCommentService
            .saveProjectPinComment(model)
            .then((res) => {
                let tempArr = [...projectPinCommentList];
                tempArr.push({
                    comment: values.comment,
                    commentedBy: auth.user.firstName + " " + auth.user.lastName,
                    commentedOn: moment().format('MM/DD/yyyy hh:mm a'),
                    id: res.id,
                    isDeleted: false,
                    poiStatus: poiStatus,
                });
                setProjectPinCommentList(tempArr);
                formikForComments.resetForm();
                showAlert({ message: res.message, variant: 'success' });
            })
            .catch((ex) => {
                showAlert({ message: ex.message, variant: 'success' });
            })
            .finally(() => {
                setSubmitting(false);
                setProjectPinCommentsLoading(false);
            });
    };

    const buildProjectPinCustomFieldToUpdate = () => {
        return selectedCustomFieldIds.map((fieldName) => {
            const selectedField = projectTypeCustomFieldList.find((field) => field.name === fieldName);

            return {
                name: selectedField.name,
                isEditable: selectedField.isEditable,
            };
        });
    };

    const updateProjectPin = (values, setSubmitting) => {
        setLoading(true);
        const projectFieldCustomFieldsToSubmit = [...filteredProjectPinCustomFields];
        projectFieldCustomFieldsToSubmit.forEach(x => {
            x.pointCategoryProjectTypeCustomFields = []
        });
        let updateProjectPinModel = {
            ...values,
            user: null,
            projectPinCheckBoxes: projectPinCheckBoxes,
            pointCategory: null,
            projectTypePOIStatus: null,
            projectPinCustomFields: projectFieldCustomFieldsToSubmit
        };

        projectPinService
            .saveProjectPin(updateProjectPinModel)
            .then((res) => {
                showAlert({ message: res.message, variant: 'success' });
                history.goBack(); // Navigate back after saving
            })
            .catch((ex) => {
                showAlert({ message: ex.message, variant: 'error' });
            })
            .finally(() => {
                setSubmitting(false);
                setLoading(false);
            });
    };

    const handleSaveCustomField = () => {
        const selectedField = projectTypeCustomFieldList.find(field => field.name === selectedProjectTypeCustomField);

        const newCustomFieldData = {
            name: selectedField.name,
            description: customFieldDescription
        };

        setCustomFieldsData(prevData => [...prevData, newCustomFieldData]);
        setIsModalOpen(false);
    };

    const handlePointCategoryChange = (selectedPointCategoryId) => {
        const associatedCustomFieldIds = projectTypeCustomFieldList
            .filter(field => field.pointCategoryProjectTypeCustomFields
                .some(assoc => assoc.pointCategoryId === selectedPointCategoryId))
            .map(field => field.id);
        const filteredFields = customFields
            .filter(field => associatedCustomFieldIds.includes(field.projectTypeCustomFieldId));

        setFilteredProjectPinCustomFields(filteredFields);
    };

    const handleSaveEditedCustomField = () => {
        const editedFieldIndex = customFieldsData.findIndex(field => field.id === editedCustomField.id);
        if (editedFieldIndex !== -1) {
            const updatedCustomField = {
                ...editedCustomField,
                description: customFieldDescription
            };

            setCustomFieldsData(prevData => [
                ...prevData.slice(0, editedFieldIndex),
                updatedCustomField,
                ...prevData.slice(editedFieldIndex + 1)
            ]);
        }

        setEditedCustomField(null);
        setIsModalOpen(false);
    }

    const handleCustomFieldDescriptionChange = (event) => {
        setCustomFieldDescription(event.target.value);
    };

    const handleCustomFieldSelectChange = (event) => {
        setSelectedProjectTypeCustomField(event.target.value);
    };

    const openModalResolvePOIStatus = () => {
        setResolvePOIStatusModalVisible(true);
    };

    const handleEditCustomField = (value) => {
        setSelectedProjectTypeCustomField(value.name);
        setEditedCustomField(value)
        setCustomFieldDescription(value.description);
        setIsModalOpen(true);
    };

    return (
        <div className="row">
            <div className="col-lg-12 col-xxl-12">
                <div className={`card card-custom card-stretch gutter-b`}>
                    <ResolvePOIStatusModal
                        projectPin={pin}
                        show={resolvePOIStatusModalVisible}
                        hide={() => setResolvePOIStatusModalVisible(false)}
                        refreshStates={resolveStatusModalRefreshStates}
                        onModalClose={() => {
                            history.goBack();
                        }}
                    />
                    <div className="card-body">
                        <form onSubmit={formik.handleSubmit}>
                            {/* Form Header */}
                            <div className="row mb-4">
                                <div className="col-lg-12">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label font-weight-bolder text-dark">
                                            Update Nspection Pins
                                        </span>
                                    </h3>
                                    {isBusinessTier &&
                                        <div className="mt-2">
                                            {pin.isResolved ? <span className="label label-md c-white bg-green label-inline font-weight-bold py-4 mr-3">
                                                Resolved
                                            </span> : <span className="label label-md c-white bg-warning label-inline font-weight-bold py-4 mr-3">
                                                Not Resolved Yet
                                            </span>}
                                            &nbsp;&nbsp;
                                            {pin.isApproved ? <span className="label label-md c-white bg-green label-inline font-weight-bold py-4 mr-3">
                                                Approved
                                            </span> : <span className="label label-md c-white bg-danger label-inline font-weight-bold py-4 mr-3">
                                                Not Approved Yet
                                            </span>}
                                        </div>
                                    }
                                </div>
                            </div>

                            {/* Form Fields */}
                            <div className="row">
                                {/* Left Column */}
                                <div className="col-lg-6">
                                    {/* N-Point Category */}
                                    <FormControl variant="outlined" className={`w-100 ${classes.formControl}`}>
                                        <InputLabel shrink={true} htmlFor="pointCategoryId">N-Point Category</InputLabel>
                                        <Select
                                            error={getErrorField(formik, "pointCategoryId")}
                                            native
                                            label="Category"
                                            inputProps={{
                                                name: 'N-Point Category',
                                                id: 'pointCategoryId',
                                            }}
                                            {...formik.getFieldProps("pointCategoryId")}
                                            onChange={e => {
                                                formik.setFieldValue("pointCategoryId", e.target.value === "" ? "" : Number(e.target.value));
                                                setSelectedPointCategory(Number(e.target.value));
                                            }}
                                        >
                                            <option aria-label="None" value="" />
                                            {
                                                pointCategoryList.map(x => <option key={x.id} value={x.id}>{x.name}</option>)
                                            }
                                        </Select>
                                        {formik.touched.pointCategoryId && formik.errors.pointCategoryId ? (
                                            <div className="text-danger">
                                                {formik.errors.pointCategoryId}
                                            </div>
                                        ) : null}
                                    </FormControl>

                                    {/* Title */}
                                    <FormControl className="w-100 mt-4">
                                        <TextField
                                            error={getErrorField(formik, "title")}
                                            id="title"
                                            InputLabelProps={{ shrink: true }}
                                            margin="normal"
                                            label="Title"
                                            variant="outlined"
                                            {...formik.getFieldProps("title")}
                                        />
                                        {formik.touched.title && formik.errors.title ? (
                                            <div className="text-danger">
                                                {formik.errors.title}
                                            </div>
                                        ) : null}
                                    </FormControl>

                                    {/* Description */}
                                    <FormControl className="w-100 mt-4">
                                        <TextField
                                            id="description"
                                            multiline
                                            rowsMax="4"
                                            InputLabelProps={{ shrink: true }}
                                            label="Description"
                                            margin="normal"
                                            variant="outlined"
                                            className="large-textarea"
                                            {...formik.getFieldProps("description")}
                                        />
                                    </FormControl>

                                    {/* Checkboxes */}
                                    <FormControl className="w-100 mt-4">
                                        <div className="row">
                                            {projectPinCheckBoxList.map((curr, index) => (
                                                <div className="col-lg-6" key={index}>
                                                    <FormControlLabel
                                                        control={
                                                            <RedCheckbox
                                                                checked={curr.isChecked}
                                                                onChange={(e, val) => {
                                                                    let tempArr = [...projectPinCheckBoxList];
                                                                    tempArr.find(x => x.name === curr.name).isChecked = val;
                                                                    setProjectPinCheckBoxes(tempArr);
                                                                }}
                                                            />
                                                        }
                                                        label={curr.name}
                                                        labelPlacement="end"
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </FormControl>
                                </div>

                                {/* Right Column */}
                                <div className="col-lg-6">
                                    {/* N-Point Status */}
                                    {isBusinessOrProfessionalTier &&
                                        <FormControl variant="outlined" className={`w-100 ${classes.formControl}`}>
                                            <InputLabel shrink={true} htmlFor="projectTypePOIStatusId">N-Point Status</InputLabel>
                                            <Select
                                                error={getErrorField(formik, "projectTypePOIStatusId")}
                                                native
                                                label="N-Point Status"
                                                inputProps={{
                                                    name: 'N-Point Status',
                                                    id: 'projectTypePOIStatusId',
                                                }}
                                                {...(formik.getFieldProps("projectTypePOIStatusId").value ? { ...formik.getFieldProps("projectTypePOIStatusId") } : {})}
                                                onChange={e => {
                                                    formik.setFieldValue("projectTypePOIStatusId", e.target.value === "" ? "" : Number(e.target.value));
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                {
                                                    poiStatusList.map(x => <option key={x.id} value={x.id}>{x.name}</option>)
                                                }
                                            </Select>
                                            {formik.touched.projectTypePOIStatusId && formik.errors.projectTypePOIStatusId ? (
                                                <div className="text-danger">
                                                    {formik.errors.projectTypePOIStatusId}
                                                </div>
                                            ) : null}
                                        </FormControl>
                                    }

                                    {/* Custom Fields */}
                                    <div className="mt-4">
                                        <ExpansionPanel>
                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography variant="h6">Custom Fields</Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <div className="w-100">
                                                    {filteredProjectPinCustomFields && filteredProjectPinCustomFields.length > 0 && filteredProjectPinCustomFields.map((field, index) => {
                                                        const validationError = validateDescription(field.description, field.customFieldType, field.options);
                                                        let inputComponent;

                                                        switch (field.customFieldType.toLowerCase()) {
                                                            case 'dropdown':
                                                                inputComponent = (
                                                                    <Select
                                                                        value={field.description || ''}
                                                                        onChange={(e) => handleDescriptionChange(e, index)}
                                                                        displayEmpty
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        className="w-100"
                                                                    >
                                                                        {field.customFieldTypeOptions.split(',').map((option, idx) => (
                                                                            <MenuItem key={idx} value={option.trim()}>
                                                                                {option}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                );
                                                                break;
                                                            case 'checkbox':
                                                                inputComponent = field.customFieldTypeOptions.split(',').map((option, idx) => (
                                                                    <div className="col-lg-12 d-flex justify-content-between flex-column pt-4" key={idx}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={field.description?.split(', ').includes(option.trim()) || false}
                                                                                    onChange={(e) => handleCheckboxChange(e, index, option.trim())}
                                                                                    name={field.name}
                                                                                />
                                                                            }
                                                                            label={option.trim()}
                                                                        />
                                                                    </div>
                                                                ));
                                                                break;
                                                            case 'multiselect':
                                                                inputComponent = (
                                                                    <Select
                                                                        multiple
                                                                        value={field.description ? field.description.split(', ') : []}
                                                                        onChange={(e) => handleDescriptionChange(e, index)}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        className="w-100"
                                                                    >
                                                                        {field.customFieldTypeOptions.split(',').map((option, idx) => (
                                                                            <MenuItem key={idx} value={option.trim()}>
                                                                                {option}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                );
                                                                break;
                                                            default:
                                                                inputComponent = (
                                                                    <TextField
                                                                        label="Description"
                                                                        value={field.description || ''}
                                                                        onChange={(e) => handleDescriptionChange(e, index)}
                                                                        multiline={field.customFieldType.toLowerCase().includes('text')}
                                                                        rowsMax={field.customFieldType.toLowerCase().includes('text') ? "4" : "1"}
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        className="w-100"
                                                                        InputLabelProps={field.customFieldType.toLowerCase().includes('date') ? { shrink: true } : undefined}
                                                                        type={field.customFieldType.toLowerCase().includes('date') ? 'date' : 'text'}
                                                                    />
                                                                );
                                                        }

                                                        return (
                                                            <div className="mb-3" key={index}>
                                                                <FormControl variant="outlined" className="w-100">
                                                                    <h5>{field.name}</h5>
                                                                    {field.customFieldType && <h6>({field.customFieldType})</h6>}
                                                                    {inputComponent}
                                                                    {validationError && <div className="text-danger">{validationError}</div>}
                                                                </FormControl>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </div>
                                </div>
                            </div>

                            {/* Comments Section */}
                            {isBusinessTier &&
                                <div className="mt-4">
                                    <form onSubmit={formikForComments.handleSubmit}>
                                        <FormControl className="w-100">
                                            <TextField
                                                id="comment"
                                                InputLabelProps={{ shrink: true }}
                                                className="large-textarea"
                                                label="Comment"
                                                multiline
                                                rowsMax="4"
                                                margin="normal"
                                                variant="outlined"
                                                error={getErrorField(formikForComments, "comment")}
                                                {...formikForComments.getFieldProps("comment")}
                                            />
                                            {formikForComments.touched.comment && formikForComments.errors.comment ? (
                                                <div className="text-danger">
                                                    {formikForComments.errors.comment}
                                                </div>
                                            ) : null}
                                        </FormControl>
                                        <div className="d-flex justify-content-end">
                                            <Button className="btn-primary mt-3" disabled={formikForComments.isSubmitting ||
                                                (formikForComments.touched && !formikForComments.isValid)}
                                                type="submit">Add Comment</Button>
                                        </div>
                                    </form>
                                    <ExpansionPanel className="mt-4">
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <h4>View Comments</h4>
                                        </ExpansionPanelSummary>
                                        <>
                                            {projectPinCommentsLoading ?
                                                <LinearProgress />
                                                :
                                                projectPinCommentList.map((comment, idx) => {
                                                    return (
                                                        <div className="comment-block-container" key={idx}>
                                                            <div className="comment-header">
                                                                <div className="">
                                                                    <p className="author">
                                                                        {comment.commentedBy}
                                                                    </p>
                                                                    <p className="timestamp">
                                                                        {moment(comment.commentedOn).format('MM/DD/yyyy hh:mm a')}
                                                                    </p>
                                                                </div>
                                                                <div className="">
                                                                    <p className="poi-status">
                                                                        {comment.poiStatus}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="comment-block">
                                                                <p>
                                                                    {comment.comment}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>
                                    </ExpansionPanel>
                                </div>
                            }

                            {/* Action Buttons */}
                            <div className="d-flex mt-4">
                                <div className="d-flex w-100">
                                    <div className="justify-content-start" style={{ width: '88%' }}>
                                        {
                                            isBusinessTier ?
                                                !(auth.role === 'SuperAdmin' || auth.role === 'Admin' || auth.role === 'Manager') ?
                                                    pin.isResolved ?
                                                        <Button className="btn-info" onClick={openModalResolvePOIStatus}>Edit Resolve Status</Button> :
                                                        <Button className="btn-primary" onClick={openModalResolvePOIStatus}>Resolve POI</Button>
                                                    :
                                                    <Button className="btn-info" onClick={openModalResolvePOIStatus}>Manage Resolve Status</Button>
                                                :
                                                <></>
                                        }
                                    </div>
                                    <div className="justify-content-end">
                                        <Button className="btn-light mr-1" onClick={() => history.goBack()}>Cancel</Button>
                                        <Button className="btn-success" disabled={formik.isSubmitting || (formik.touched && !formik.isValid) || hasCustomFieldErrors() || (auth.role === 'Viewer')} type="submit">Save Changes
                                        {loading && <span className="ml-3 mr-3 spinner spinner-white"></span>}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </form >
                        {/* Custom Field Modal */}
                        {isModalOpen && (
                            <div className="custom-field-modal">
                                {/* Custom Field Modal Content */}
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{editedCustomField ? 'Edit Custom Field' : 'Add Custom Field'}</h5>
                                        <button type="button" className="close" onClick={() => {
                                            setEditedCustomField(null);
                                            setIsModalOpen(false);
                                        }}>
                                            <span>&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body" style={{ maxHeight: 685, overflowY: 'scroll' }}>
                                        <div className="row" style={{ alignContent: 'center' }}>
                                            <div className="col-lg-12">
                                                <FormControl variant="outlined" className={`w-100 ${classes.formControl}`}>
                                                    <InputLabel id="custom-field-select-label">Custom Field</InputLabel>
                                                    <Select
                                                        labelId="custom-field-select-label"
                                                        id="custom-field-select"
                                                        disabled={editedCustomField}
                                                        value={selectedProjectTypeCustomField}
                                                        onChange={handleCustomFieldSelectChange}
                                                    >
                                                        {projectTypeCustomFieldList
                                                            .filter(field => !selectedProjectTypeCustomFields.includes(field.name))
                                                            .map(field => (
                                                                <MenuItem key={field.id} value={field.name}>
                                                                    {field.name}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                                <FormControl variant="outlined" className={`w-100 mt-4 ${classes.formControl}`}>
                                                    <TextField
                                                        id="custom-field-description"
                                                        label="Description"
                                                        multiline
                                                        rows={4}
                                                        value={customFieldDescription}
                                                        onChange={handleCustomFieldDescriptionChange}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        {editedCustomField && (
                                            <Button variant="primary" onClick={handleSaveEditedCustomField}>Save Changes</Button>
                                        )}
                                        {!editedCustomField && (
                                            <Button variant="primary" onClick={handleSaveCustomField}>Save</Button>
                                        )}
                                        <Button variant="secondary" onClick={() => {
                                            setEditedCustomField(null);
                                            setIsModalOpen(false);
                                        }}>Close</Button>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    </div >
                </div >
            </div >
        </div >
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, alert.actions)(NspectionPinsManagePage);
