/* eslint-disable no-restricted-imports */
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as projectTypeCheckBoxService from "../../../services/settings/projectTypeCheckBoxService";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import ProjectTypeCheckBoxValidator from "../../../helpers/validators/ProjectTypeCheckBoxValidator";
import { getErrorField } from "../../../helpers/helper";
import * as alert from "../../../redux/alertSnackBarRedux";
import * as confirmModal from "../../../redux/confirmModalRedux";
import TextField from '@material-ui/core/TextField';
import { blue } from '@material-ui/core/colors';
import { makeStyles, withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ProjectTypeNavbar from '../../components/ProjectTypeNavbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
const BlueCheckbox = withStyles({
    root: {
        color: blue[400],
        '&$checked': {
            color: blue[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


var initialProjectTypeCheckBox = {
    id: 0,
    name: '',
    projectTypeId: '',
    isDeleted: false
}

const ProjectTypeCheckBoxListingPage = (props) => {
    const location = useLocation();
    const { id } = useParams();
    const { projectTypeId, projectType } = location.state || {};
    const { showAlert, showConfirmModal, hideConfirmModal, history } = props;
    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProjectTypeCheckBox, setSelectedProjectTypeCheckBox] = useState(initialProjectTypeCheckBox);

    useEffect(() => {
        loadTableData();
    }, [loadTableData])

    const loadTableData = useCallback(() => {
        projectTypeCheckBoxService.getProjectTypeCheckBoxByProjectTypeId(id).then(res => {
            setData(res);
        }).catch((ex) => {
            showAlert({ message: ex.message, variant: "error" });
        });
    })

    const editProjectTypeCheckBox = (id) => {
        projectTypeCheckBoxService.getProjectTypeCheckBoxById(id).then(res => {
            setSelectedProjectTypeCheckBox(res);
            setIsModalOpen(true);
        })
    }

    // const deleteProjectTypeCheckBox = (id) => {
    //     projectTypeCheckBoxService.deleteProjectTypeCheckBox(id).then(res => {
    //         showAlert({ message: res.message, variant: "success" });
    //         loadTableData();
    //         hideConfirmModal();
    //     })
    // }

    const deleteProjectTypeCheckBox = (id, projectTypeId) => {
        let newModel = { ...selectedProjectTypeCheckBox, id: id, projectTypeId: projectTypeId, isDeleted: true }
        projectTypeCheckBoxService.deleteProjectTypeCheckBoxIsDeletedFlag(newModel).then(res => {
            showAlert({ message: res.message, variant: "success" });
            loadTableData();
            hideConfirmModal();
        })
    }

    const columns = [
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "isDefault",
            label: "Is Public",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    return data[dataIndex].isDefault &&
                        <span className="label label-lg c-white bg-success label-inline font-weight-bold py-4 mr-3 mt-2 mb-2" style={{
                            whiteSpace: 'nowrap'
                        }}>
                            {'User Upload Value'}
                        </span>
                }

            }
        },
        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                width: 1,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            <button
                                className="btn btn-sm btn-info font-weight-bold py-2 px-3 px-xxl-5 my-1"
                                onClick={() => {
                                    editProjectTypeCheckBox(data[dataIndex].id);
                                }}
                            >
                                Edit
                            </button> &nbsp;
                            <button
                                onClick={() => showConfirmModal({
                                    title: "Delete N-Point Category",
                                    text: "Are you sure you want to delete this n-point category?",
                                    rightBtnText: "Confirm",
                                    btnAction: () => deleteProjectTypeCheckBox(data[dataIndex].id, data[dataIndex].projectTypeId)
                                })}
                                className="btn btn-sm btn-danger font-weight-bold py-2 px-3 px-xxl-5 my-1"
                            >
                                Delete
                            </button>
                        </>
                    );
                }
            },
        },
    ];

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedProjectTypeCheckBox(initialProjectTypeCheckBox);
        formik.resetForm();
    }

    const saveProjectTypeCheckBox = (values, setStatus, setSubmitting) => {
        let newProjTypeCheckBoxes = { ...values, projectTypeId: id };
        projectTypeCheckBoxService.saveProjectTypeCheckBox(newProjTypeCheckBoxes).then(res => {
            showAlert({ message: res.message, variant: "success" });
            loadTableData();
            handleModalClose();

        }).catch((ex) => {
            showAlert({ message: ex.message, variant: "error" });
        })
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: selectedProjectTypeCheckBox,
        validationSchema: ProjectTypeCheckBoxValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            saveProjectTypeCheckBox(values, setStatus, setSubmitting);
        }
    });

    return (
        <div className="row">
            <Modal centered size="md" show={isModalOpen} onHide={handleModalClose}>
                <form className="card card-custom" onSubmit={formik.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Check Box</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form">
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <TextField
                                        error={getErrorField(formik, "name")}
                                        id="outlined-name"
                                        label="Name"
                                        margin="normal"
                                        variant="outlined"
                                        {...formik.getFieldProps("name")}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <div className="text-danger">
                                            {formik.errors.name}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <FormControl className="col-lg-12 d-flex justify-content-between flex-column pt-4">
                                <div className="checkbox-container">
                                    {
                                        <FormControlLabel
                                            control={
                                                <BlueCheckbox
                                                    checked={formik.values.isDefault}
                                                    onChange={(e, val) => {
                                                        formik.setFieldValue("isDefault", val);
                                                    }}
                                                />
                                            }
                                            label="Is Public"
                                            labelPlacement="end"
                                        />
                                    }
                                </div>
                            </FormControl>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        <button
                            type="submit"
                            className="btn btn-success mr-2"
                            disabled={
                                formik.isSubmitting || (formik.touched && !formik.isValid)
                            }
                        >
                            Save
                            {formik.isSubmitting}
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            <div className="col-lg-12 col-xxl-12">
                <div className={`card card-custom card-stretch gutter-b}`}>
                    <div className="card-header border-0 py-5">
                        <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                        Nspection Type Name: {projectType?.name}
                            </span>
                            <span className="card-label font-weight-bolder text-dark">
                                CheckBox
                            </span>
                        </h3>
                        <div className="card-toolbar">
                            <button
                                onClick={() => setIsModalOpen(true)}
                                className="btn btn-success font-weight-bolder font-size-sm mr-3"
                            >
                                <span className="fa fa-plus"></span> Add CheckBox
                            </button>
                        </div>
                    </div>
                    {/* <Navbar>
                        <Container fluid>
                            <Navbar.Brand className='btn btn-warning c-white' href="/projectTypes">Back to Nspection Type</Navbar.Brand>
                            <Nav className="me-auto">
                                <Nav.Link className='btn btn-info c-white ml-2' href={`/settings/point-category-listing/${id}`}>N-Point Categories</Nav.Link>
                                <Nav.Link className='btn btn-info c-white ml-2' href={`/settings/projectType-checkbox/${id}`}>Checkboxes</Nav.Link>
                                <Nav.Link className='btn btn-info c-white ml-2' href={`/settings/poi-listing/${id}`}>N-Point Statuses</Nav.Link>
                                <Nav.Link className='btn btn-info c-white ml-2' href={`/settings/projectType-customField/${id}`}>Custom Fields</Nav.Link>
                                <Nav.Link className='btn btn-info c-white ml-2' href={`/settings/projectType-metaDataField/${id}`}>Meta Data Fields</Nav.Link>
                            </Nav>
                        </Container>
                    </Navbar> */}
                     <ProjectTypeNavbar id={id} projectType={projectType} />
                    <div className="container-fluid">
                        <MUIDataTable
                            data={data}
                            columns={columns}
                            options={{
                                selectableRows: 'none'
                            }}
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default connect(null, { ...alert.actions, ...confirmModal.actions })(ProjectTypeCheckBoxListingPage);