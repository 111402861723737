/* eslint-disable no-restricted-imports */
import React, { useState, useEffect, useCallback } from 'react';
import MUIDataTable from "mui-datatables";
import { makeStyles, withStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { connect } from "react-redux";
import { useParams, useLocation } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import * as projectTypeCustomFieldService from "../../../services/settings/projectTypeCustomFieldService";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { red, blue } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useFormik } from "formik";
import ProjectTypeCustomFieldValidator from "../../../helpers/validators/ProjectTypeCustomFieldValidator";
import { getErrorField } from "../../../helpers/helper";
import * as alert from "../../../redux/alertSnackBarRedux";
import * as confirmModal from "../../../redux/confirmModalRedux";
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Container from 'react-bootstrap/Container';
import ProjectTypeNavbar from '../../components/ProjectTypeNavbar';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

var initialProjectTypeCustomField = {
    id: 0,
    name: '',
    isEditable: false,
    isViewable: false,
    projectTypeId: '',
    customFieldType: '',
    isDeleted: false
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const BlueCheckBox = withStyles({
    root: {
        color: blue[400],
        '&$checked': {
            color: blue[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const fieldTypeList = [
    { value: 'fractional', label: 'Text (Fractional)' },
    { value: 'decimal', label: 'Text (Decimal)' },
    { value: 'fractionalWithUnit', label: 'Text (Fractional with Unit on Inches)' },
    { value: 'date', label: 'Text (Date)' },
    { value: 'textual', label: 'Text (Plain)' },
    { value: 'checkbox', label: 'Checkbox' },
    { value: 'dropdown', label: 'Dropdown' },
    { value: 'multiselect', label: 'Multiselect' }
];

const ProjectTypeCustomFieldListingPage = (props) => {
    const location = useLocation();
    const { id } = useParams();
    const { projectTypeId, projectType } = location.state || {};
    const { showAlert, showConfirmModal, hideConfirmModal, history } = props;
    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProjectTypeCustomField, setSelectedProjectTypeCustomField] = useState(initialProjectTypeCustomField);
    const [options, setOptions] = useState([]);
    const [showFieldOptions, setShowFieldOptions] = useState(false);

    useEffect(() => {
        loadTableData();
    }, [loadTableData]);

    const addOption = () => {
        setOptions([...options, ""]);
    };

    const removeOption = index => {
        const newOptions = options.filter((_, i) => i !== index);
        setOptions(newOptions);
    };

    const handleOptionChange = (value, index) => {
        const newOptions = options.map((option, i) => {
            if (i === index) {
                return value;
            }
            return option;
        });
        setOptions(newOptions);
    };

    const loadTableData = useCallback(() => {
        projectTypeCustomFieldService.getProjectTypeCustomFieldByProjectTypeId(id).then(res => {
            setData(res);
        }).catch((ex) => {
            showAlert({ message: ex.message, variant: "error" });
        });
    })

    const editProjectTypeCustomField = (id) => {
        projectTypeCustomFieldService.getProjectTypeCustomFieldById(id).then(res => {
            setSelectedProjectTypeCustomField(res);
            if (res.customFieldTypeOptions) {
                setOptions(res.customFieldTypeOptions.split(","));
                setShowFieldOptions(true);
            }
            setIsModalOpen(true);
        })
    }

    const deleteProjectTypeCustomField = (id, projectTypeId) => {
        let newModel = { ...selectedProjectTypeCustomField, id: id, projectTypeId: projectTypeId, isDeleted: true }
        projectTypeCustomFieldService.deleteProjectTypeCustomFieldIsDeletedFlag(newModel).then(res => {
            showAlert({ message: res.message, variant: "success" });
            loadTableData();
            hideConfirmModal();
        })
    }

    const columns = [
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "isEditable",
            label: "Is Editable In Report",
            options: {
                filter: false,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {data[dataIndex].isEditable ?
                                <span className="label label-lg c-white bg-blue label-inline font-weight-bold py-4 mr-3 mt-2 mb-2" style={{
                                    whiteSpace: 'nowrap'
                                }}>
                                    {'true'}
                                </span>
                                : "-"}
                        </>
                    )
                }
            },
        },
        {
            name: "isViewable",
            label: "Is Viewable In Report",
            options: {
                filter: false,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {data[dataIndex].isViewable ?
                                <span className="label label-lg c-white bg-blue label-inline font-weight-bold py-4 mr-3 mt-2 mb-2" style={{
                                    whiteSpace: 'nowrap'
                                }}>
                                    {'true'}
                                </span>
                                : "-"}
                        </>
                    )
                }
            },
        },
        {
            name: "customFieldType",
            label: "Custom Field Data Type",
            options: {
                filter: false,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {data[dataIndex].customFieldType ?
                                data[dataIndex].customFieldType
                                : "-"}
                        </>
                    )
                }
            },
        },
        {
            name: "customFieldTypeOptions",
            label: "Custom Field Data Type Options",
            options: {
                filter: false,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {data[dataIndex].customFieldTypeOptions ?
                                data[dataIndex].customFieldTypeOptions
                                : "-"}
                        </>
                    )
                }
            },
        },
        {
            name: "isDefault",
            label: "Is Public",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    return data[dataIndex].isDefault &&
                        <span className="label label-lg c-white bg-success label-inline font-weight-bold py-4 mr-3 mt-2 mb-2" style={{
                            whiteSpace: 'nowrap'
                        }}>
                            {'User Upload Value'}
                        </span>
                }

            }
        },
        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                width: 1,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            <button
                                className="btn btn-sm btn-info font-weight-bold py-2 px-3 px-xxl-5 my-1"
                                onClick={() => {
                                    editProjectTypeCustomField(data[dataIndex].id);
                                }}
                            >
                                Edit
                            </button> &nbsp;
                            <button
                                onClick={() => showConfirmModal({
                                    title: "Delete N-Point Custom Field",
                                    text: "Are you sure you want to delete this n-point field? This will delete all the data related to custom fields in the Nspections.",
                                    rightBtnText: "Confirm",
                                    btnAction: () => deleteProjectTypeCustomField(data[dataIndex].id, data[dataIndex].projectTypeId)
                                })}
                                className="btn btn-sm btn-danger font-weight-bold py-2 px-3 px-xxl-5 my-1"
                            >
                                Delete
                            </button>
                        </>
                    );
                }
            },
        },
    ];

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedProjectTypeCustomField(initialProjectTypeCustomField);
        setOptions([]);
        setShowFieldOptions(false);
        formik.resetForm();
    }

    const handleCustomFieldTypeChange = (e) => {
        const selectedType = e.target.value;
        formik.setFieldValue("customFieldType", selectedType);

        if (selectedType !== 'dropdown' && selectedType !== 'multiselect') {
            formik.setFieldValue("customFieldTypeOptions", "");
        }
    };

    const saveProjectTypeCustomField = (values, setStatus, setSubmitting) => {
        const optionsString = options.join(",");
        let newProjTypeCustomFields = { ...values, projectTypeId: id, customFieldTypeOptions: optionsString };
        projectTypeCustomFieldService.saveProjectTypeCustomField(newProjTypeCustomFields).then(res => {
            showAlert({ message: res.message, variant: "success" });
            loadTableData();
            handleModalClose();

        }).catch((ex) => {
            showAlert({ message: ex.message, variant: "error" });
        })
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: selectedProjectTypeCustomField,
        validationSchema: ProjectTypeCustomFieldValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            saveProjectTypeCustomField(values, setStatus, setSubmitting);
        }
    });

    return (
        <div className="row">
            <Modal centered size="md" show={isModalOpen} onHide={handleModalClose}>
                <form className="card card-custom" onSubmit={formik.handleSubmit}>
                    <Modal.Header closeButton>
                    Nspection Type Name: {projectType?.name}
                        <br />
                        <Modal.Title>Custom Field</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form">
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <TextField
                                        error={getErrorField(formik, "name")}
                                        id="outlined-name"
                                        label="Name"
                                        margin="normal"
                                        variant="outlined"
                                        {...formik.getFieldProps("name")}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <div className="text-danger">
                                            {formik.errors.name}
                                        </div>
                                    ) : null}
                                </div>
                                &nbsp;
                                <div className='col-lg-12'>
                                    <FormControl variant="outlined" style={{ width: '100%', marginTop: 10 }}>
                                        <InputLabel htmlFor="outlined-age-native-simple">Custom Field Type</InputLabel>
                                        <Select
                                            error={getErrorField(formik, "customFieldType")}
                                            native
                                            label="Custom Field Type"
                                            onChange={e => {
                                                if (e.target.value === "") {
                                                    formik.setFieldValue("customFieldType", "");
                                                }
                                                else {
                                                    formik.setFieldValue("customFieldType", e.target.value);
                                                    if (e.target.value === 'checkbox' || e.target.value === 'multiselect' || e.target.value === 'dropdown') {
                                                        setShowFieldOptions(true);
                                                    } else {
                                                        setShowFieldOptions(false);
                                                        setOptions([]);
                                                    }
                                                }
                                            }}
                                            value={formik.values.customFieldType ? formik.values.customFieldType : ''}
                                            // {...formik.getFieldProps("customFieldType")}
                                            inputProps={{
                                                name: 'customFieldType',
                                                id: 'outlined-age-native-simple',
                                            }}
                                        >
                                            <option aria-label="None" value="" />
                                            {
                                                fieldTypeList.map(x => <option key={x.value} value={x.value}>{x.label}</option>)
                                            }

                                        </Select>
                                        {formik.touched.customFieldType && formik.errors.customFieldType ? (
                                            <div className="text-danger">
                                                {formik.errors.customFieldType}
                                            </div>
                                        ) : null}
                                    </FormControl>
                                </div>
                                &nbsp;
                                <div className="col-lg-12">
                                    <TextField
                                        error={getErrorField(formik, "customFieldTypeOptions")}
                                        id="outlined-name"
                                        disabled
                                        label="Custom Field Type Options"
                                        margin="normal"
                                        variant="outlined"
                                        value={options.join(",")}
                                    // {...formik.getFieldProps("customFieldTypeOptions")}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <div className="text-danger">
                                            {formik.errors.name}
                                        </div>
                                    ) : null}
                                </div>
                                &nbsp;

                                {
                                    showFieldOptions &&
                                    (
                                        <>
                                            <div className='col-lg-12' style={{ width: '100%' }}>
                                                <span className="text mt-3 font-weight-bold fz-xs">
                                                    Manage your custom field options
                                                </span>
                                                <div className='col-lg-12' style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                                                    <Button className='btn btn-sm btn-info' onClick={addOption}>
                                                        Add Option
                                                    </Button>
                                                </div>
                                            </div>


                                            <div className='col-lg-12'>
                                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                                    {options.map((option, index) => (
                                                        <div key={index} className='col-lg-12' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <FormControl variant="outlined" className='col-lg-6' >
                                                                <TextField
                                                                    margin="normal"
                                                                    value={option}
                                                                    onChange={(e) => handleOptionChange(e.target.value, index)}
                                                                />
                                                            </FormControl>
                                                            <div className='col-lg-2' style={{ display: 'flex', alignItems: 'end' }}>
                                                                <Button variant="danger" className='btn btn-sm' onClick={() => removeOption(index)}>
                                                                    Remove
                                                                </Button>
                                                            </div>

                                                        </div>
                                                    ))}
                                                    &nbsp;
                                                </FormControl>

                                            </div>
                                        </>
                                    )
                                }
                                &nbsp;
                                <div className="col-lg-12">
                                    <div className="checkbox-container">
                                        {
                                            <FormControlLabel
                                                control={
                                                    <BlueCheckBox
                                                        checked={formik.values.isEditable}
                                                        onChange={(e, val) => {
                                                            formik.setFieldValue("isEditable", val);
                                                        }}
                                                    />
                                                }
                                                label="Is Editable in Report"
                                                labelPlacement="end"
                                            />
                                        }
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="checkbox-container">
                                        {
                                            <FormControlLabel
                                                control={
                                                    <BlueCheckBox
                                                        checked={formik.values.isViewable}
                                                        onChange={(e, val) => {
                                                            formik.setFieldValue("isViewable", val);
                                                        }}
                                                    />
                                                }
                                                label="Is Viewable in Report"
                                                labelPlacement="end"
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <FormControl className="col-lg-12 d-flex justify-content-between flex-column pt-4">
                                        <div className="checkbox-container">
                                            {
                                                <FormControlLabel
                                                    control={
                                                        <BlueCheckBox
                                                            checked={formik.values.isDefault}
                                                            onChange={(e, val) => {
                                                                formik.setFieldValue("isDefault", val);
                                                            }}
                                                        />
                                                    }
                                                    label="Is Default"
                                                    labelPlacement="end"
                                                />
                                            }
                                        </div>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        <button
                            type="submit"
                            className="btn btn-success mr-2"
                            disabled={
                                formik.isSubmitting || (formik.touched && !formik.isValid)
                            }
                        >
                            Save
                            {formik.isSubmitting}
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            <div className="col-lg-12 col-xxl-12">
                <div className={`card card-custom card-stretch gutter-b}`}>
                    <div className="card-header border-0 py-5">
                        <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                                {projectType?.name}
                            </span>
                            <span className="card-label font-weight-bolder text-dark">
                                Custom Fields
                            </span>
                        </h3>
                        <div className="card-toolbar">
                            <button
                                onClick={() => setIsModalOpen(true)}
                                className="btn btn-success font-weight-bolder font-size-sm mr-3"
                            >
                                <span className="fa fa-plus"></span> Add Custom Field
                            </button>
                        </div>
                    </div>
                    <ProjectTypeNavbar id={id} projectType={projectType} />
                    <div className="container-fluid">
                        <MUIDataTable
                            data={data}
                            columns={columns}
                            options={{
                                selectableRows: 'none'
                            }}
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default connect(null, { ...alert.actions, ...confirmModal.actions })(ProjectTypeCustomFieldListingPage);