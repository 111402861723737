/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import constants from "../../constants/constants";
import TextField from '@material-ui/core/TextField';
import { useFormik } from "formik";
import UserValidator from "../../helpers/validators/UserValidator";
import { getErrorField } from "../../helpers/helper";
import * as clientService from "../../services/management/clientService";
import * as roleService from "../../services/management/roleService";
import * as userService from "../../services/management/userService";
import * as alert from "../../redux/alertSnackBarRedux";
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import { values } from 'lodash';

const initialState = {
    id: 0,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    clientId: 0,
    username: '',
    password: '',
    role: '',
    roleId: '',
    isDeleted: false
}

const AddUserModal = (props) => {
    const { showAlert, show, hide, reloadTable, userToEditId, resetUserToEdit } = props;
    const [disableClientSelectField, setDisableClientSelectField] = useState(false);
    const [user, setUser] = useState(initialState);
    const [clientList, setClientList] = useState([]);
    const [roleList, setRoleList] = useState([]);

    useEffect(() => {
        clientService.getClients().then(res => {
            setClientList(res);
        });
        roleService.getRoles().then(res => {
            setRoleList(res);
        })

    }, [show]);

    useEffect(() => {
        if (userToEditId) {
            fetchSavedUser();
        } else {
            setUser(initialState);
            resetUserToEdit();
            formik.resetForm();
        }
    }, [fetchSavedUser, formik, resetUserToEdit, userToEditId]);

    const fetchSavedUser = () => {
        userService.getUserById(userToEditId).then(res => {
            setUser(res);
            (res.role.roleName === 'SuperAdmin') && setDisableClientSelectField(true);
        })
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: user,
        validationSchema: UserValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            saveUser(values, setSubmitting);
        }
    });

    const returnSelectedRole = (value) => {
        const selectedValue = Number(value.roleId);
        return roleList.find(role => role.roleId === selectedValue)?.roleName;
    }

    const saveUser = (values, setSubmitting) => {
        var selectedRole = returnSelectedRole(values);
        let userModel = { ...values, clientId: (selectedRole === 'SuperAdmin') ? null : Number(values.clientId) };
        console.log('model', userModel);
        if (!(userModel.role === 'SuperAdmin') && userModel.clientId === 0) {
            showAlert({ message: "Please select a client if a user is not a super admin", variant: 'warning' });
            setSubmitting(false);
            return;
        }
        if (user.id > 0) {
            userService.updateUser(userModel).then(res => {
                showAlert({ message: res.message, variant: 'success' });
                reloadTable();
                hideUserModal();
            }).catch(ex => {
                showAlert({ message: ex.message, variant: 'error' })
            }).finally(() => {
                setSubmitting(false);
            })
        } else {
            registerUser(userModel, setSubmitting);
        }
    }

    const registerUser = (userModel, setSubmitting) => {
        if (userModel.password === "") {
            showAlert({ message: "Please enter password for the user", variant: 'warning' });
            setSubmitting(false);
            return;
        }

        userService.registerUser(userModel).then(res => {
            showAlert({ message: res.message, variant: 'success' });
            reloadTable();
            hideUserModal();
        }).catch(ex => {
            showAlert({ message: ex.message, variant: 'error' })
        }).finally(() => {
            setSubmitting(false);
        })
    }

    const hideUserModal = () => {
        setUser(initialState);
        resetUserToEdit();
        formik.resetForm();
        props.hide();
    }

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.show}
            onHide={hideUserModal}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {userToEditId > 0 ? "Edit" : "Add"} User
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-6">
                            <TextField
                                error={getErrorField(formik, "firstName")}
                                id="outlined-name"
                                label="First Name"
                                margin="normal"
                                variant="outlined"
                                {...formik.getFieldProps("firstName")}
                            />

                            {formik.touched.firstName && formik.errors.firstName ? (
                                <div className="text-danger">
                                    {formik.errors.firstName}
                                </div>
                            ) : null}
                        </div>

                        <div className="col-lg-6">
                            <TextField
                                error={getErrorField(formik, "lastName")}
                                id="outlined-name"
                                label="Last Name"
                                margin="normal"
                                variant="outlined"
                                {...formik.getFieldProps("lastName")}
                            />

                            {formik.touched.lastName && formik.errors.lastName ? (
                                <div className="text-danger">
                                    {formik.errors.lastName}
                                </div>
                            ) : null}
                        </div>
                        <div className="col-lg-6">
                            <TextField
                                error={getErrorField(formik, "phoneNumber")}
                                id="outlined-name"
                                label="Phone Number"
                                margin="normal"
                                variant="outlined"
                                {...formik.getFieldProps("phoneNumber")}
                            />

                            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                                <div className="text-danger">
                                    {formik.errors.phoneNumber}
                                </div>
                            ) : null}
                        </div>
                        {
                            <div className="col-lg-6">
                                <FormControl variant="outlined" style={{ width: '100%', marginTop: 15 }}>
                                    <InputLabel htmlFor="outlined-age-native-simple">Client</InputLabel>
                                    <Select
                                        disabled={disableClientSelectField}
                                        native
                                        label="Client"
                                        inputProps={{
                                            name: 'Client',
                                            id: 'outlined-age-native-simple',
                                        }}
                                        {...formik.getFieldProps("clientId")}
                                        onChange={e => {
                                            if (e.target.value === "") {
                                                formik.setFieldValue("clientId", "");
                                            }
                                            else {
                                                formik.setFieldValue("clientId", Number(e.target.value));
                                            }
                                        }}
                                    >

                                        <option aria-label="None" value="0" />
                                        {
                                            clientList.map(x => <option key={x.id} value={x.id}>{x.businessName}</option>)
                                        }
                                    </Select>
                                    {formik.touched.clientId && formik.errors.clientId ? (
                                        <div className="text-danger">
                                            {formik.errors.clientId}
                                        </div>
                                    ) : null}
                                </FormControl>
                            </div>
                        }


                        <div className="col-lg-6">
                            <TextField
                                error={getErrorField(formik, "username")}
                                id="outlined-name"
                                label="Username"
                                margin="normal"
                                variant="outlined"
                                {...formik.getFieldProps("username")}
                            />

                            {formik.touched.username && formik.errors.username ? (
                                <div className="text-danger">
                                    {formik.errors.username}
                                </div>
                            ) : null}
                        </div>

                        <div className="col-lg-6">
                            {
                                userToEditId > 0 ? <></> :
                                    <>
                                        <TextField
                                            error={getErrorField(formik, "password")}
                                            id="outlined-name"
                                            label="Password"
                                            type="password"
                                            margin="normal"
                                            variant="outlined"
                                            {...formik.getFieldProps("password")}
                                        />

                                        {formik.touched.password && formik.errors.password ? (
                                            <div className="text-danger">
                                                {formik.errors.password}
                                            </div>
                                        ) : null}
                                    </>
                            }
                        </div>
                        {
                            <div className="col-lg-6">
                                <FormControl variant="outlined" style={{ width: '100%', marginTop: 15 }}>
                                    <InputLabel htmlFor="outlined-age-native-simple">Role</InputLabel>
                                    <Select
                                        native
                                        label="Role"
                                        inputProps={{
                                            name: 'Role',
                                            id: 'outlined-age-native-simple',
                                        }}
                                        {...formik.getFieldProps("roleId")}
                                        onChange={e => {
                                            var selectedRole = returnSelectedRole(e.target.value);
                                            if (selectedRole === "") {
                                                formik.setFieldValue("roleId", "");
                                            }
                                            else {
                                                formik.setFieldValue("roleId", Number(e.target.value));
                                                if(selectedRole === "SuperAdmin") {
                                                    formik.setFieldValue("clientId", "");
                                                    setDisableClientSelectField(true);
                                                }
                                                else{
                                                    setDisableClientSelectField(false);
                                                }
                                            }
                                        }}
                                    >
                                        <option aria-label="None" value="0" label='' />
                                        {
                                            roleList.map(x => <option key={x.roleId} value={x.roleId} label={x.roleName}>{x.roleName}</option>)
                                        }

                                    </Select>
                                    {formik.touched.roleId && formik.errors.roleId ? (
                                        <div className="text-danger">
                                            {formik.errors.roleId}
                                        </div>
                                    ) : null}
                                </FormControl>
                            </div>
                        }


                        {/* <div className="col-lg-6">
                            <FormControlLabel
                                value="end"
                                control={
                                    <BlueCheckBox
                                        checked={formik.values.isAdmin}
                                        {...formik.getFieldProps("isAdmin")}
                                    />
                                }
                                label="Set as admin"
                                labelPlacement="end"
                            />
                        </div> */}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="btn-light" onClick={hideUserModal}>Cancel</Button>
                    <Button className="btn-success" disabled={formik.isSubmitting || (formik.touched && !formik.isValid)} type="submit"> {userToEditId > 0 ? "Save" : "Add"} User</Button>
                </Modal.Footer>
            </form>

        </Modal>
    );
}

export default connect(null, alert.actions)(AddUserModal);