/* eslint-disable no-restricted-imports */
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as pointCategoryService from "../../../services/settings/pointCategoryService";
import * as projectTypeCustomFieldService from "../../../services/settings/projectTypeCustomFieldService";
import { Button, Modal } from "react-bootstrap";
import { blue } from '@material-ui/core/colors';
import { useFormik } from "formik";
import { makeStyles, withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import PointCategoryValidator from "../../../helpers/validators/PointCategoryValidator";
import { getErrorField } from "../../../helpers/helper";
import * as alert from "../../../redux/alertSnackBarRedux";
import * as confirmModal from "../../../redux/confirmModalRedux";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import ProjectTypeNavbar from '../../components/ProjectTypeNavbar';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const BlueCheckbox = withStyles({
    root: {
        color: blue[400],
        '&$checked': {
            color: blue[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

var initialPointCategory = {
    id: 0,
    name: '',
    description: '',
    projectTypeId: '',
    projectTypeCustomFieldId: 0,
    isDeleted: false,
    isDefault: false
}

const PointCategorysListingPage = (props) => {
    const location = useLocation();
    const { id } = useParams();
    const { projectTypeId, projectType } = location.state || {};
    const { showAlert, showConfirmModal, hideConfirmModal, history } = props;
    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPointCategory, setSelectedPointCategory] = useState(initialPointCategory);
    const [projectTypeCustomFields, setProjectTypeCustomFields] = useState([]);
    const [selectedCustomFields, setSelectedCustomFields] = useState([]);

    useEffect(() => {
        loadTableData();
        loadProjectTypeCustomFields();
    }, [loadProjectTypeCustomFields, loadTableData])

    const loadProjectTypeCustomFields = () => {
        if (id) {
            projectTypeCustomFieldService.getProjectTypeCustomFieldByProjectTypeId(id).then(res => {
                const formattedFields = res.map(field => ({
                    value: field.id,
                    label: field.name,
                    isDefault: field.isDefault
                }));
                setProjectTypeCustomFields(formattedFields);
            }).catch((ex) => {
                showAlert({ message: ex.message, variant: "error" });
            });
        }
    };

    const loadTableData = useCallback(() => {

        if (id) {
            pointCategoryService.getPointCategoryByProjectTypeId(id).then(res => {
                setData(res);
            }).catch((ex) => {
                showAlert({ message: ex.message, variant: "error" });
            });
        }
        else {
            pointCategoryService.getPointCategories().then(res => {
                setData(res);
            }).catch((ex) => {
                showAlert({ message: ex.message, variant: "error" });
            });
        }

    })

    const editPointCategory = (id) => {
        pointCategoryService.getPointCategoryById(id).then(res => {
            setSelectedPointCategory(res);
            console.log('here', res);
            if (res.isDefault) {
                setSelectedCustomFields(res.pointCategoryProjectTypeCustomFields.filter(field => field.projectTypeCustomField.isDefault).map(x => x.projectTypeCustomFieldId))
            } else {
                setSelectedCustomFields(res.pointCategoryProjectTypeCustomFields.filter(field => !field.projectTypeCustomField.isDefault).map(x => x.projectTypeCustomFieldId));
            }
            setIsModalOpen(true);
        })
    }

    // const deletePointCategory = (id) => {
    //     pointCategoryService.deletePointCategory(id).then(res => {
    //         showAlert({ message: res.message, variant: "success" });
    //         loadTableData();
    //         hideConfirmModal();
    //     })
    // }

    const deletePointCategory = (id, projectTypeId) => {
        let deleteModel = { ...selectedPointCategory, isDeleted: true, projectTypeId: projectTypeId, id: id }
        pointCategoryService.deletePointCategoryIsDeletedFlag(deleteModel).then(res => {
            showAlert({ message: res.message, variant: "success" });
            loadTableData();
            hideConfirmModal();
        })
    }

    const columns = [
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "description",
            label: "Description",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {data[dataIndex].description ? data[dataIndex].description : "-"}
                        </>
                    );
                }
            }
        },
        {
            name: "pointCategoryProjectTypeCustomFields",
            label: "Custom Fields",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const customFields = data[dataIndex].pointCategoryProjectTypeCustomFields.length > 0 ? data[dataIndex].pointCategoryProjectTypeCustomFields.map(x => x.projectTypeCustomField.name).join(',  ') : '-';

                    return <>{customFields}</>;
                }
            }
        },
        {
            name: "isDefault",
            label: "Is Public",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    return data[dataIndex].isDefault &&
                        <span className="label label-lg c-white bg-success label-inline font-weight-bold py-4 mr-3 mt-2 mb-2" style={{
                            whiteSpace: 'nowrap'
                        }}>
                            {'User Upload Value'}
                        </span>
                }

            }
        },
        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                width: 1,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            <button
                                className="btn btn-sm btn-info font-weight-bold py-2 px-3 px-xxl-5 my-1"
                                onClick={() => editPointCategory(data[dataIndex].id)}
                            >
                                Edit
                            </button> &nbsp;
                            <button
                                onClick={() => showConfirmModal({
                                    title: "Delete N-Point Category",
                                    text: "Are you sure you want to delete this n-point category?",
                                    rightBtnText: "Confirm",
                                    btnAction: () => deletePointCategory(data[dataIndex].id, data[dataIndex].projectTypeId)
                                })}
                                className="btn btn-sm btn-danger font-weight-bold py-2 px-3 px-xxl-5 my-1"
                            >
                                Delete
                            </button>
                        </>
                    );
                }
            },

        },
    ];

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedPointCategory(initialPointCategory);
        setSelectedCustomFields([]);
        formik.resetForm();
    }

    const savePointCategory = (values, setStatus, setSubmitting) => {
        console.log(values);
        let newValues = { ...values, projectTypeId: id, projectTypeCustomFieldIds: selectedCustomFields };
        pointCategoryService.savePointCategory(newValues).then(res => {
            showAlert({ message: res.message, variant: "success" });
            loadTableData();
            handleModalClose();
        }).catch((ex) => {
            showAlert({ message: ex.message, variant: "error" });
        })
    }

    const handleCustomFieldChange = (event) => {
        setSelectedCustomFields(event.target.value);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: selectedPointCategory,
        validationSchema: PointCategoryValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            savePointCategory(values, setStatus, setSubmitting);
        }
    });

    return (
        <div className="row" >
            <Modal centered size="md" show={isModalOpen} onHide={handleModalClose}>
                <form className="card card-custom" onSubmit={formik.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>N-Point Category</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form">
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <TextField
                                        error={getErrorField(formik, "name")}
                                        id="outlined-name"
                                        label="Name"
                                        margin="normal"
                                        variant="outlined"
                                        {...formik.getFieldProps("name")}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <div className="text-danger">
                                            {formik.errors.name}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <TextField
                                        error={getErrorField(formik, "description")}
                                        id="outlined-name"
                                        label="Description"
                                        multiline
                                        margin="normal"
                                        variant="outlined"
                                        {...formik.getFieldProps("description")}
                                    />
                                    {formik.touched.description && formik.errors.description ? (
                                        <div className="text-danger">
                                            {formik.errors.description}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <FormControl className="col-lg-12 d-flex justify-content-between flex-column pt-4">
                                    <div className="checkbox-container">
                                        {
                                            <FormControlLabel
                                                control={
                                                    <BlueCheckbox
                                                        checked={formik.values.isDefault}
                                                        onChange={(e, val) => {
                                                            formik.setFieldValue("isDefault", val);
                                                        }}
                                                    />
                                                }
                                                label="Is Public"
                                                labelPlacement="end"
                                            />
                                        }
                                    </div>
                                </FormControl>
                            </div>
                            <FormControl variant="outlined" fullWidth margin="normal">
                                <InputLabel id="project-type-custom-field-select-label">Project Type Custom Fields</InputLabel>
                                <Select
                                    labelId="project-type-custom-field-select-label"
                                    id="project-type-custom-field-select"
                                    multiple
                                    value={selectedCustomFields}
                                    onChange={handleCustomFieldChange}
                                >

                                    {projectTypeCustomFields.map((field) => (
                                        <MenuItem key={field.value} value={field.value}>
                                            {field.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        <button
                            type="submit"
                            className="btn btn-success mr-2"
                            disabled={
                                formik.isSubmitting || (formik.touched && !formik.isValid)
                            }
                        >
                            Save
                            {formik.isSubmitting}
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            <div className="col-lg-12 col-xxl-12">
                <div className={`card card-custom card-stretch gutter-b}`}>
                    <div className="card-header border-0 py-5">
                        <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                                Nspection Type Name: {projectType?.name}
                            </span>
                            <span className="card-label font-weight-bolder text-dark">
                                N-Point Category
                            </span>
                   
                        </h3>
                        <div className="card-toolbar">
                            <button
                                onClick={() => setIsModalOpen(true)}
                                className="btn btn-success font-weight-bolder font-size-sm mr-3"
                            >
                                <span className="fa fa-plus"></span> Add N-Point Category
                            </button>
                        </div>
                    </div>
                    <ProjectTypeNavbar id={id} projectType={projectType} />
                    {/* <Navbar>
                        <Container fluid>
                            <Navbar.Brand className='btn btn-warning c-white' href="/projectTypes">Back to Nspection Type</Navbar.Brand>
                            <Nav className="me-auto">
                                <Nav.Link className='btn btn-info c-white ml-2' href={`/settings/point-category-listing/${id}`}>N-Point Categories</Nav.Link>
                                <Nav.Link className='btn btn-info c-white ml-2' href={`/settings/projectType-checkbox/${id}`}>Checkboxes</Nav.Link>
                                <Nav.Link className='btn btn-info c-white ml-2' href={`/settings/poi-listing/${id}`}>N-Point Statuses</Nav.Link>
                                <Nav.Link className='btn btn-info c-white ml-2' href={`/settings/projectType-customField/${id}`}>Custom Fields</Nav.Link>
                                <Nav.Link className='btn btn-info c-white ml-2' href={`/settings/projectType-metaDataField/${id}`}>Meta Data Fields</Nav.Link>
                            </Nav>
                        </Container>
                    </Navbar> */}
                    <div className="container-fluid">
                        <MUIDataTable
                            data={data}
                            columns={columns}
                            options={{
                                selectableRows: 'none'
                            }}
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default connect(null, { ...alert.actions, ...confirmModal.actions })(PointCategorysListingPage);