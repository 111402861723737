/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import * as clientService from "../../../../../services/management/clientService";

export function QuickActions({ role, clientRefId }) {
  const [paymentProfileLinks, setPaymentProfileLinks] = useState({});

  const loadPaymentProfileLink = (id) => {
    const existingLinkInfo = paymentProfileLinks[id];

    if (existingLinkInfo) {
      const expiresAt = new Date(existingLinkInfo.expiresAt);
      const now = new Date();

      if (expiresAt > now) {
        window.open(existingLinkInfo.url, '_blank'); // Open URL in a new tab
        return;
      }
    }

    clientService.getManagementLink(id).then(res => {
      if (res && res.url && res.expiresAt) {
        const expiresAt = new Date(res.expiresAt);
        const now = new Date();

        if (expiresAt > now) {
          setPaymentProfileLinks({
            ...paymentProfileLinks,
            [id]: { url: res.url, expiresAt: res.expiresAt } // Store the URL and its expiry time
          });
          window.open(res.url, '_blank'); // Open URL in a new tab
        } else {
          console.log("Link has expired");
        }
      } else {
        console.log("Failed to get a valid URL or expiry date.");
      }
    }).catch(ex => {
      console.log(ex);
    })
  }

  const closeMenu = () => {
    const closeButton = document.getElementById('kt_quick_actions_close');
    if (closeButton) {
      closeButton.click();
    }
  };

  return (
    <>
      {/* begin::Quick Actions Panel */}
      <div id="kt_quick_actions" className="offcanvas offcanvas-left p-10">
        {/* begin::Header */}
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-10">
          <h3 className="font-weight-bold m-0">
            Quick Actions
            <small className="text-muted font-size-sm ml-2">
              {/* finance & reports */}
            </small>
          </h3>
          <a
            href="#"
            className="btn btn-xs btn-icon btn-light btn-hover-danger"
            id="kt_quick_actions_close"
          >
            <i className="ki ki-close icon-xs text-muted"></i>
          </a>
        </div>
        {/* end::Header */}

        {/* begin::Content */}
        <div className="offcanvas-content pr-5 mr-n5">
          {
            role !== 'Viewer' && role !== 'User' &&
            (
              <div className="row gutter-b">
                {/* begin::Item */}
                <div className="col-6">
                  <Link
                    to="/projects"
                    onClick={closeMenu}
                    className="btn btn-block btn-light btn-hover-warning text-dark-50 text-center py-10 px-5"
                  >
                    <span className="svg-icon svg-icon-3x svg-icon-warning m-0">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}
                      />
                    </span>
                    <span className="d-block font-weight-bold font-size-h6 mt-2">
                      Nspections
                    </span>
                  </Link>
                </div>
                {/* end::Item */}
                <div className="col-6">
                  <Link
                    to="/projectTypes"
                    onClick={closeMenu}
                    className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
                  >
                    <span className="svg-icon svg-icon-3x svg-icon-warning m-0">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Shopping/Box1.svg")}
                      />
                    </span>
                    <span className="d-block font-weight-bold font-size-h7 mt-2">
                      Nspection Types
                    </span>
                  </Link>
                </div>
                {/* begin::Item */}


                {/* end::Item */}
              </div>
            )
          }
          <div className="row gutter-b">

            {/* begin::Item */}
            {
              (role === 'SuperAdmin' || role === 'Admin') &&
              <div className="col-6">
                <Link to="/users"  onClick={closeMenu}>
                  <a
                    href="#"
                    className="btn btn-block btn-light btn-hover-info text-dark-50 text-center py-10 px-5"
                  >
                    <span className="svg-icon svg-icon-3x svg-icon-info m-0">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}
                      />
                    </span>
                    <span className="d-block font-weight-bold font-size-h6 mt-2">
                      Users
                    </span>
                  </a>
                </Link>
              </div>
            }
            {/* end::Item */}

            {/* begin::Item */}
            {
              (role !== 'Viewer') &&
              <div className="col-6">
                <Link to="/clients"  onClick={closeMenu}>
                  <a
                    href="#"
                    className="btn btn-block btn-light btn-hover-success text-dark-50 text-center py-10 px-5"
                  >
                    <span className="svg-icon svg-icon-3x svg-icon-success m-0">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Design/Color-profile.svg"
                        )}
                      />
                    </span>
                    <span className="d-block font-weight-bold font-size-h6 mt-2">
                      Clients
                    </span>
                  </a>
                </Link>
              </div>
            }

          </div>
          {
            role === 'SuperAdmin' &&
            <div className="row gutter-b">
              {/* begin::Item */}
              <div className="col-6">
                <Link
                  to="/settings/setting"
                  onClick={closeMenu}
                  className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
                >
                  <span className="svg-icon svg-icon-3x svg-icon-info m-0">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
                    />
                  </span>
                  <span className="d-block font-weight-bold font-size-h6 mt-2">
                    Settings
                  </span>
                </Link>
              </div>
            </div>
          }

          {
            role === 'Admin' &&
            <div className="row gutter-b">
              {/* begin::Item */}
              <div className="col-6">
                <a
                  onClick={() => loadPaymentProfileLink(clientRefId)}
                  className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
                >
                  <span className="svg-icon svg-icon-3x svg-icon-info m-0">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
                    />
                  </span>
                  <span className="d-block font-weight-bold font-size-h6 mt-2">
                    Billing Profile
                  </span>
                </a>
              </div>
            </div>
          }


          <div className="row gutter-b">
            {/* begin::Item */}
            {/* <div className="col-6">
            <Link
              to="/settings/point-category-listing"
              href="#"
              className="btn btn-block btn-light btn-hover-grey text-dark-50 text-center py-10 px-5"
            >
              <span className="svg-icon svg-icon-3x svg-icon-dark-75 m-0">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Code/Settings4.svg"
                  )}
                />
              </span>
              <span className="d-block font-weight-bold font-size-h6 mt-2">
                Settings
                </span>
            </Link>
          </div> */}

            {/* <div className="col-6">
              <a
                href="#"
                className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
              >
                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Shopping/Chart-bar1.svg"
                    )}
                  />
                </span>
                <span className="d-block font-weight-bold font-size-h6 mt-2">
                  Email
                </span>
              </a>
            </div> */}
            {/* end::Item */}

            {/* begin::Item */}
            {/* <div className="col-6">
              <a
                href="#"
                className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
              >
                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Design/Color-profile.svg"
                    )}
                  />
                </span>
                <span className="d-block font-weight-bold font-size-h6 mt-2">
                  Clients
                </span>
              </a>
            </div> */}
            {/* end::Item */}
          </div>
          <div className="row">
            {/* begin::Item */}
            {/* <div className="col-6">
              <a
                href="#"
                className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
              >
                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Euro.svg")}
                  />
                </span>
                <span className="d-block font-weight-bold font-size-h6 mt-2">
                  Orders
                </span>
              </a>
            </div> */}
            {/* end::Item */}
          </div>
        </div>
        {/* end::Content */}
      </div>
      {/* end::Quick Actions Panel */}
    </>
  );
}
